import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import logoTitle from "../../images/oss-platform/title_logo.svg";
import Newsletter from "../../components/Newsletter";
import ContactUs from "../../components/ContactUs";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import Icon1 from "../../images/oss-platform/card_icon_1.svg";
import Icon2 from "../../images/oss-platform/card_icon_2.svg";
import Icon3 from "../../images/oss-platform/card_icon_3.svg";
import Icon4 from "../../images/oss-platform/card_icon_4.svg";
import Icon5 from "../../images/oss-platform/card_icon_5.svg";
import Icon from "../../images/oss-platform/card_title.svg";

import "./OSSPlatformPage.css";

export default function OSSPlatformPage() {
    return (
        <div className="page-container oss-platform">
            <Header className="header-oss-platform" />
            <div id="title">
                <div className="content">
                    <div className="box">
                        <div className="box-title">
                            <h1>welcome to the reinforcing platform</h1>
                        </div>
                        <div className="box-logo">
                            <img src={logoTitle} alt="reinforcing" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="introduction">
                <div className="content">
                    <div className="introduction-title">
                        <h3>Open and Responsible Research and Innovation Platform</h3>
                    </div>
                    <div className="introduction-body">
                        <h5>
                            The one-stop source to knowledge and expertise to
                            support you on your ORRI journey. All services are
                            easily accessible, and tailored to community needs.
                        </h5>
                    </div>
                </div>
            </div>
            <div className="platform">
                <div className="content">
                    <div className="container">
                        <div className="row-1">
                            <div className="platform-card">
                                <a href="/oss-platform/services?tab=0" className="platform-card-link">
                                    <div className="row">
                                        <div className="platform-card-title">
                                            Grants
                                        </div>
                                        <div className="platform-card-logo">
                                            <img src={Icon1} alt="grants_icon" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="platform-card-body">
                                            <h5>
                                                Grants to financially support your pilots in Open
                                                and Responsible R&I (ORRI) are already available.
                                            </h5>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="title">
                                <img src={Icon} alt="icon" />
                            </div>
                            <div className="platform-card">
                                <a href="/oss-platform/services?tab=1" className="platform-card-link">
                                    <div className="row">
                                        <div className="platform-card-title" id="platform-card-title-2">
                                            ORRI Community
                                        </div>
                                        <div className="platform-card-logo" id="platform-card-logo-2">
                                            <img src={Icon2} alt="orri_community_icon" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="platform-card-body">
                                            <h5>
                                                Find peers and experts to help you in
                                                your journey towards ORRI implementation.
                                            </h5>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="row-2">
                            <div className="platform-card">
                                <a href="/oss-platform/services?tab=2" className="platform-card-link">
                                    <div className="row">
                                        <div className="platform-card-title">
                                            Tools
                                        </div>
                                        <div className="platform-card-logo">
                                            <img src={Icon3} alt="tools_icon" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="platform-card-body">
                                            <h5>
                                                Resources to support you in implementing your
                                                ORRI project with tools and best practices.
                                            </h5>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="platform-card disabled">
                                <a href="/oss-platform/services?tab=3" className="platform-card-link">
                                    <div className="row">
                                        <div className="platform-card-title">
                                            Training
                                        </div>
                                        <div className="platform-card-logo">
                                            <img src={Icon4} alt="training_icon" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="platform-card-body">
                                            <h5>
                                                Find training modules on specific topics related to ORRI.
                                            </h5>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="platform-card disabled">
                                <a href="/oss-platform/services?tab=4" className="platform-card-link">
                                    <div className="row">

                                        <div className="platform-card-title">
                                            Pathways
                                        </div>
                                        <div className="platform-card-logo">
                                            <img src={Icon5} alt="pathways_icon" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="platform-card-body">
                                            <h5>
                                                Explore pathways to find the right resources
                                                fitting your concrete ORRI challenge.
                                            </h5>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="footer">
                <h3 id="footer-1">
                    Soon you will find also Trainings for building ORRI capacity
                    in your organization as well as Pathways and Tools to find
                    the right resources for your ORRI journey from idea to practice.
                </h3>
                <h3 id="footer-2">
                    Interested?
                </h3>
            </div> */}
            <Newsletter />
            <ContactUs />
            <SocialAndNewsletter />
            <Footer />
        </div>
    );
}