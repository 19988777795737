import logoTitle from "../../images/oss-platform/services_title_logo.svg";
import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import InfoIcon from "../../images/oss-platform/info_icon.svg";
import Newsletter from "../../components/Newsletter";
import ContactUs from "../../components/ContactUs";
import React, { useEffect, useState } from 'react';
import Header from "../../components/Header";
import { FaArrowLeft } from "react-icons/fa6";
import { CgArrowLongRight } from "react-icons/cg";
import Footer from "../../components/Footer";
import ReactPaginate from "react-paginate";

import Icon1 from "../../images/oss-platform/tab_icon_1.svg";
import Icon2 from "../../images/oss-platform/tab_icon_2.svg";
import Icon3 from "../../images/oss-platform/tab_icon_3.svg";
import Icon4 from "../../images/oss-platform/tab_icon_4.svg";
import Icon5 from "../../images/oss-platform/tab_icon_5.svg";

import TabCardIcon1 from "../../images/oss-platform/tab_card_icon_1.svg";
import TabCardIcon2 from "../../images/oss-platform/tab_card_icon_2.svg";
import TabCardIcon3 from "../../images/oss-platform/tab_card_icon_3.svg";
import TabCardIcon4 from "../../images/oss-platform/tab_card_icon_4.svg";
import TabCardIcon5 from "../../images/oss-platform/tab_card_icon_5.svg";
import TabCardIcon6 from "../../images/oss-platform/tab_card_icon_6.svg";

import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { NotificationContainer, NotificationManager } from 'react-notifications';
import "react-datepicker/dist/react-datepicker.css";
import "react-notifications/lib/notifications.css";

import "./OSSPlatformServicesPage.css";

export default function OSSPlatformServicesPage() {

    const [service, setService] = useState(0);
    const [grantee, setGrantee] = useState(0);
    const [showResults, setShowResults] = useState(false);
    const [selectedResult, setSelectedResult] = useState(null);
    const [results, setResults] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [metadata, setMetadata] = useState({});

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const tab = queryParams.get('tab');
        if (tab) {
            setService(parseInt(tab, 10));
        }
    }, []);

    const handleTabChange = (number) => {
        if (number === 1) {
            setGrantee(0);
        } else if (number === 2) {
            setSelectedItems([]);
            setSelectedResult(null);
            setResults([]);
            setShowResults(false);
            setCurrentPage(1);
        }
        setService(number);
    }

    const selectItem = (item) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(item)) {
                return prevSelectedItems.filter(selectedItem => selectedItem !== item);
            } else {
                return [...prevSelectedItems, item];
            }
        });
    }

    // const handleSubmit = () => {
    //     NotificationManager.error('Search is not available yet', 'Error!');
    // }

    const [selectedYear, setSelectedYear] = useState(null);

    const handleYearChange = (date) => {
        setSelectedYear(date);
    };

    // ---------------------------- NEW TOOL FORM ---------------------------- //

    const currentYear = new Date().getFullYear();

    const [wordLimitReached50, setWordLimitReached50] = useState(false);

    const handleWordTitleLimit = (e) => {
        const inputValue = e.target.value;
        const words = inputValue.trim().split(/\s+/);

        if (words.length > 50) {
            e.preventDefault();
            const limitedWords = words.slice(0, 50).join(' ');
            e.target.value = limitedWords;
            setWordLimitReached50(true);
        } else {
            setWordLimitReached50(false);
        }
    };

    const [wordLimitReached50Authors, setWordLimitReached50Authors] = useState(false);

    const handleWordTitleLimitAuthors = (e) => {
        const inputValue = e.target.value;
        const words = inputValue.trim().split(/\s+/);

        if (words.length > 50) {
            e.preventDefault();
            const limitedWords = words.slice(0, 50).join(' ');
            e.target.value = limitedWords;
            setWordLimitReached50Authors(true);
        } else {
            setWordLimitReached50Authors(false);
        }
    };

    const [wordLimitReached300, setWordLimitReached300] = useState(false);

    const handleWordSummaryLimit = (e) => {
        const inputValue = e.target.value;
        const words = inputValue.trim().split(/\s+/);

        if (words.length > 300) {
            e.preventDefault();
            const limitedWords = words.slice(0, 300).join(' ');
            e.target.value = limitedWords;
            setWordLimitReached300(true);
        } else {
            setWordLimitReached300(false);
        }
    };

    const createResourceSchema = yup.object({
        resource_name: yup.string().required('Resource name is required'),
        resource_authors: yup.string().required('Resource authors are required'),
        summary: yup.string().required('Summary is required'),
        publication_year: yup.number().required('Publication year is required').min(1700, 'Publication year cannot be before 1700').max(currentYear, `Publication year cannot exceed ${currentYear}`).typeError('Publication year must be a number'),
        link: yup.string().required('Link is required').url('Must be a valid URL'),
        resource_type: yup.string().required('Resource type is required'),
        keywords: yup.array().of(yup.string()).required().min(1, 'At least one keyword must be selected'),
        potential_users: yup.array().of(yup.string()).required().min(1, 'At least one potential user must be selected')
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    const { handleSubmit, register, reset, formState: { errors } } = useForm({
        resolver: yupResolver(createResourceSchema)
    });

    const [selectedImage, setSelectedImage] = useState(null);
    const [potentialUsers, setPotentialUsers] = useState([]);
    const [resourceTypes, setResourceTypes] = useState([]);
    const [keywords, setKeywords] = useState([]);

    useEffect(() => {
        fetch(`${apiUrl}/api/rri-tools/resource-types`)
            .then(response => response.json())
            .then(data => {
                setResourceTypes(data);
            })
            .catch(error => {
                console.error("Error fetching resource types:", error);
            });

        fetch(`${apiUrl}/api/rri-tools/keywords`)
            .then(response => response.json())
            .then(data => {
                setKeywords(data);
            })
            .catch(error => {
                console.error("Error fetching keywords:", error);
            });

        fetch(`${apiUrl}/api/rri-tools/resource-potential-users`)
            .then(response => response.json())
            .then(data => {
                setPotentialUsers(data);
            })
            .catch(error => {
                console.error("Error fetching potential users:", error);
            });
    }, []);

    const handleImageChange = event => {
        if (event.target.files[0]) {
            setSelectedImage(event.target.files[0]);
        }
    };

    const showToolResults = (selected) => {
        let page = currentPage;
        if (selected) {
            setCurrentPage(selected);
            page = selected;
        }
        fetch(`${apiUrl}/api/rri-tools/filtered?page=${page}&size=${postsPerPage}&keywords=${selectedItems.join(',')}`)
            .then(response => {
                const pagination = JSON.parse(response.headers.get('x-pagination'));
                setMetadata(pagination);
                setTotalPages(pagination.TotalPages);
                return response.json()
            })
            .then(data => {
                setResults(data);
                setShowResults(true);
            })
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        document.getElementById('image').value = '';
    };

    const selectResult = (result) => {
        fetch(`${apiUrl}/api/rri-tools/${result}`)
            .then(response => response.json())
            .then(data => {
                setSelectedResult(data);
            });
    };

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('authors', data.resource_authors);
        formData.append('title', data.resource_name);
        formData.append('summary', data.summary);
        formData.append('yearOfPublication', data.publication_year);
        formData.append('url', data.link);

        formData.append('resourceTypes', data.resource_type);
        formData.append('keywords', data.keywords ? data.keywords.join(',') : '');
        formData.append('resourcePotentialUsers', data.potential_users ? data.potential_users.join(',') : '');

        if (selectedImage) {
            formData.append('file', selectedImage);
        }

        try {
            const response = await fetch(`${apiUrl}/api/rri-tools`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                NotificationManager.success('Your form has been successfully uploaded', 'Submitted!');

                reset({
                    resource_name: '',
                    resource_authors: '',
                    summary: '',
                    publication_year: 0,
                    image_resolution: '',
                    link: '',
                    resource_type: '',
                    keywords: [],
                    potential_users: []
                });

                setSelectedImage(null);
                setSelectedYear(null);
            } else {
                NotificationManager.error('A problem occurred while submitting the form', 'Error!');
                console.error('Error:', await response.text());
            }
        } catch (error) {
            console.error('Request failed:', error);
        }
    };

    return (
        <div className="page-container oss-platform-services">
            <NotificationContainer />
            <Header className="header-oss-platform-services" />
            <div id="title">
                <div className="content">
                    <div className="banner">
                        <div className="banner-text">
                            <div className="banner-title">
                                <h1>orri one stop source</h1>
                            </div>
                            <div className="banner-subtitle">
                                {service === 0 && (
                                    <h3>grants</h3>
                                )}
                                {service === 1 && (
                                    <h3>orri community</h3>
                                )}
                                {service === 2 && (
                                    <h3>tools</h3>
                                )}
                                {service === 3 && (
                                    <h3>training</h3>
                                )}
                                {service === 4 && (
                                    <h3>pathways</h3>
                                )}
                                {service === 5 && (
                                    <h3>form</h3>
                                )}
                            </div>
                        </div>
                        <div className="banner-logo">
                            <img src={logoTitle} alt="reinforcing" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="introduction">
                <div className="content">
                    <div className="introduction-body">
                        {service === 0 && (
                            <h5>
                                REINFORCING provides financial support to European
                                organizations in the form of grants to enable and
                                support their journey towards open and responsible
                                research and innovation.
                            </h5>
                        )}
                        {service === 1 && (
                            <h5>
                                From experienced organizations to facilitators,
                                this is where you can find peers who, just like you,
                                have had their own ORRI journeys and can share more
                                about their experiences.
                            </h5>
                        )}
                        {service === 2 && (
                            <h5>
                                This is where you find a wealth of useful resources
                                to support you in designing your ORRI project with
                                concrete tools and best practices for implementing ORRI.
                            </h5>
                        )}
                        {service === 3 && (
                            <h5>
                                Training is essential to acquire the right skillset
                                you need to implement ORRI projects. Here you can find
                                training modules on general and specific topics related
                                to ORRI.
                            </h5>
                        )}
                        {service === 4 && (
                            <h5>

                            </h5>
                        )}
                        {service === 5 && (
                            <h5>
                                REINFORCING provides the possibility of submitting
                                your own tools through the following form.
                            </h5>
                        )}
                    </div>
                </div>
            </div>
            <div className="services">
                { /*<div className="searcher">
                    <div className="search-group">
                        <div className="input-wrapper">
                            <input type="search" id="search-input" className="form-control" placeholder="Search..." aria-label="Search" aria-describedby="search-addon" />
                            <span className="search-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>*/}
                <div className="services-tabs pt-4">
                    <ul className="nav nav-tabs justify-content-center">
                        <li className="nav-item">
                            <a
                                className={`nav-link ${service === 0 ? 'active' : ''}`}
                                aria-current="page"
                                href="/#"
                                onClick={(event) => { event.preventDefault(); handleTabChange(0); }}
                            >
                                <div className="tab_title">
                                    <img src={Icon1} alt="icon_grants" />
                                    <h1>Grants</h1>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${service === 1 ? 'active' : ''}`}
                                href="/#"
                                onClick={(event) => { event.preventDefault(); handleTabChange(1); }}
                            >
                                <div className="tab_title">
                                    <img src={Icon2} alt="icon_orri_community" />
                                    <h1>ORRI Community</h1>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${service === 2 ? 'active' : ''}`}
                                href="/#"
                                onClick={(event) => { event.preventDefault(); handleTabChange(2); }}
                            >
                                <div className="tab_title">
                                    <img src={Icon3} alt="icon_tools" />
                                    <h1>Tools</h1>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item disabled">
                            <a
                                className={`nav-link ${service === 3 ? 'active' : ''}`}
                                href="/#"
                                onClick={(event) => { event.preventDefault(); handleTabChange(3); }}
                            >
                                <div className="tab_title">
                                    <img src={Icon4} alt="icon_training" />
                                    <h1>Training</h1>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item disabled">
                            <a
                                className={`nav-link ${service === 4 ? 'active' : ''}`}
                                href="/#"
                                onClick={(event) => { event.preventDefault(); handleTabChange(4); }}
                            >
                                <div className="tab_title">
                                    <img src={Icon5} alt="icon_pathways" />
                                    <h1>Pathways</h1>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <div className="content-2">
                        <div className="services-tabs-body">
                            {service === 0 && (
                                <div className="services-tabs-body-data">
                                    <div className="services-tabs-body-data-title">
                                        <h1>Information</h1>
                                    </div>
                                    <div className="services-tabs-body-data-cards">
                                        <div className="services-tabs-cards-info">
                                            <h3>Find general information about funding</h3>
                                            <div className="services-tabs-card">
                                                <a href="/open-calls/open-calls" id="open-call-link">General Information
                                                    <img id="card_icon_1" src={TabCardIcon1} alt="card_icon" />
                                                </a>

                                            </div>
                                        </div>
                                        <div className="services-tabs-cards-info">
                                            <h3>Find funding for your ORRI project</h3>
                                            <div className="services-tabs-card">
                                                <a href="/open-calls/current-calls" id="current-call-link">Current Call
                                                    <img id="card_icon_2" src={TabCardIcon2} alt="card_icon" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="services-tabs-cards-info disabled">
                                            <h3>Info on ORRI Booster and Incubator projects</h3>
                                            <div className="services-tabs-card">
                                                <a href="/#" id="previous-call-link">Previous Call
                                                    <img id="card_icon_4" src={TabCardIcon4} alt="card_icon" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {service === 1 && grantee === 0 && (
                                <div className="services-tabs-body-data">
                                    <div className="services-tabs-body-data">
                                        <div className="services-tabs-body-data-cards" id="services-tabs-body-data-cards-2">
                                            <div className="services-tabs-cards-info">
                                                <div className="services-tabs-card">
                                                    <a href="/orri-map" id="open-call-link">ORRI Map
                                                        <img id="card_icon_3" src={TabCardIcon3} alt="card_icon" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="services-tabs-cards-info">
                                                <div className="services-tabs-card">
                                                    <a href="/#" id="current-call-link"
                                                        onClick={(event) => { event.preventDefault(); setGrantee(1); }}>REINFORCING Grantees
                                                        <img id="card_icon_5" src={TabCardIcon5} alt="card_icon" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="services-tabs-cards-info disabled">
                                                <div className="services-tabs-card">
                                                    <a href="/#" id="previous-call-link" className="two-lines">
                                                        <span>Sibling Projects & Platforms</span>
                                                        <img id="card_icon_6" src={TabCardIcon6} alt="card_icon" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {service === 1 && grantee === 1 && (
                                <>
                                    <div className="breadcrumb">
                                        Orri Community / <span>Grantees</span>
                                    </div>
                                    <div className="back" onClick={(event) => { event.preventDefault(); setGrantee(0); }}>
                                        <FaArrowLeft />Back to ORRI Community
                                    </div>
                                    <div className="grantee-background-left"></div>
                                    <div className="grantee-background"></div>
                                    <div className="grantee-background-right"></div>
                                    <div className="grantee-title">
                                        Get to know our grantees and their projects
                                    </div>
                                    <div className="services-tabs-body-data-grantees">
                                        <div className="services-tabs-body-data-cards">
                                            <div className="services-tabs-cards-info">
                                                <div className="services-tabs-card">
                                                    <div className="title">Responsible Open Innovation</div>
                                                    <div className="opencall">Grantees from the 1st Open Call</div>
                                                    <div className="callDate">Boosters Nov' 23 <CgArrowLongRight />
                                                    </div>
                                                </div>
                                                <a href="/#"
                                                    onClick={(event) => { event.preventDefault(); setGrantee(2); }}>
                                                </a>
                                            </div>
                                            <div className="services-tabs-cards-info disabled">
                                                <div className="services-tabs-card">
                                                    <div className="title">ORRI in the Balkans</div>
                                                    <div className="opencall">Grantees from the 2nd Open Call</div>
                                                    <div className="callDate">Incubators Mar' 24 <CgArrowLongRight />
                                                    </div>
                                                </div>
                                                { /*<a href="/#"
                                                    onClick={(event) => { event.preventDefault(); setGrantee(3); }}>
                                                </a>*/ }
                                            </div>
                                            <div className="services-tabs-cards-info disabled">
                                                <div className="services-tabs-card">
                                                    <div className="title">Multi-stakeholder engagement in the EU Missions</div>
                                                    <div className="opencall">Grantees from the 3rd Open Call</div>
                                                    <div className="callDate">Incubators July 24 <CgArrowLongRight />
                                                    </div>
                                                </div>
                                                { /*<a href="/#"
                                                    onClick={(event) => { event.preventDefault(); setGrantee(4); }}>
                                                </a>*/ }
                                            </div>
                                            <div className="services-tabs-cards-info disabled">
                                                <div className="services-tabs-card">
                                                    <div className="title">REINFORCING</div>
                                                    <div className="opencall">4th Open Call</div>
                                                    <div className="callDate">Boosters Nov' 24 <CgArrowLongRight />
                                                    </div>
                                                </div>
                                                { /*<a href="/#"
                                                    onClick={(event) => { event.preventDefault(); setGrantee(5); }}>
                                                </a>*/ }
                                            </div>
                                            <div className="services-tabs-cards-info disabled">
                                                <div className="services-tabs-card">
                                                    <div className="title">REINFORCING</div>
                                                    <div className="opencall">5th Open Call</div>
                                                    <div className="callDate">Boosters Mar' 25 <CgArrowLongRight />
                                                    </div>
                                                </div>
                                                { /*<a href="/#"
                                                    onClick={(event) => { event.preventDefault(); setGrantee(6); }}>
                                                </a>*/ }
                                            </div>
                                            <div className="services-tabs-cards-info disabled">
                                                <div className="services-tabs-card">
                                                    <div className="title">REINFORCING</div>
                                                    <div className="opencall">6th Open Call</div>
                                                    <div className="callDate">Incubators July 25 <CgArrowLongRight />
                                                    </div>
                                                </div>
                                                { /*<a href="/#"
                                                    onClick={(event) => { event.preventDefault(); setGrantee(7); }}>
                                                </a>*/ }
                                            </div>
                                            <div className="services-tabs-cards-info disabled">
                                                <div className="services-tabs-card">
                                                    <div className="title">REINFORCING</div>
                                                    <div className="opencall">7th Open Call</div>
                                                    <div className="callDate">Boosters Nov' 25 <CgArrowLongRight />
                                                    </div>
                                                </div>
                                                { /*<a href="/#"
                                                    onClick={(event) => { event.preventDefault(); setGrantee(8); }}>
                                                </a>*/ }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {service === 1 && grantee === 2 && (
                                <>
                                    <div className="breadcrumb">
                                        Orri Community / Grantees / <span>REINFORCING First Open Call</span>
                                    </div>
                                    <div className="back" onClick={(event) => { event.preventDefault(); setGrantee(1); }}>
                                        <FaArrowLeft />Back to Grantees
                                    </div>
                                    <div className="grantee-opencall-subtitle">
                                        1st OPEN CALL ON RESPONSIBLE OPEN INNOVATION
                                    </div>
                                    <div className="grantee-opencall-title">
                                        Boosters
                                    </div>
                                    <div className="grantee-opencall-text">
                                        In November 2023, we launched <u>REINFORCING’s first open call (Boosters Call)</u> for Responsible Open Innovation projects.
                                        The call targeted applicants with proven experience in ORRI practices and addressed three specific challenges:
                                        multi-stakeholder engagement, intellectual property rights mechanisms as well as impact assessment of responsibility.
                                    </div>
                                    <div className="grantee-opencall-projects-text">
                                        Thanks to a meticulous evaluation process supported by External Evaluators, the following 6 projects were selected.
                                    </div>
                                    <div className="grantee-opencall-projects">
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                CoCOI FoodTech
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                (Co-Creation of Open Innovation in Food Technology)
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Led by the Institute for Food Technology of Novi Sad in Serbia, this project aims to enhance multi-stakeholder
                                                engagement throughout the research process in food and feed technology.
                                            </div>
                                            <div className="grantee-opencall-projects-card-link">
                                                watch their video presentation here
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                aRRIba
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                Early Age Entrepreneurship Promo(tion to Boost Scientific-Technological Vocation)
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Coordinated by “Fundación Centro Europeo de Empresas e Innovación de Murcia”, based in Spain, this project
                                                focuses on fostering scientific entrepreneurship among primary school students in the Spanish Region of Murcia, utilizing a multi-stakeholder approach.
                                            </div>
                                            <div className="grantee-opencall-projects-card-link">
                                                watch their video presentation here
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                D-EUROB models
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                (Business Models Facilitating Open Science in Digital Euro Research)
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Coordinated by Alis Grave Nil in Bulgaria, this project aims to adapt the company’s business model to support Open Research and
                                                responsible sharing of research findings concerning the Digital Euro, encouraging collaboration and innovation within the digital currency ecosystem.
                                            </div>
                                            <div className="grantee-opencall-projects-card-link">
                                                watch their video presentation here
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                DDEEP
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                (Disabled Data European Engagement Path)
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Led by the Italian limited liability company, FTS, this project seeks to standardize an ORRI-based approach, examining the needs for
                                                disability data across Europe, and expand the project through multi-stakeholder engagement. The initiative builds upon the
                                                launch in November 2022 of Fightthestroke.org, in which FTS holds a 48% ownership stake.
                                            </div>
                                            <div className="grantee-opencall-projects-card-link">
                                                watch their video presentation here
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                IP2024
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                (Impact Pathways 2024 – An Interactive Tool to Integrate Societal Impact and RRI Principles in R&I Processes)
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Led by SoScience, a pioneering European startup dedicated to Responsible Research and Innovation, this project aims to co-design and develop a free online interactive
                                                tool to assist organizations, consortia, and researchers in focusing on key impact elements for developing responsible R&I projects.
                                            </div>
                                            <div className="grantee-opencall-projects-card-link">
                                                watch their video presentation here
                                            </div>
                                        </div>
                                        <div className="grantee-opencall-projects-card">
                                            <div className="grantee-opencall-projects-card-title">
                                                TOG Eye-Tracking
                                            </div>
                                            <div className="grantee-opencall-projects-card-subtitle">
                                                (Together To Go Eye - Tracking)
                                            </div>
                                            <div className="grantee-opencall-projects-card-text">
                                                Led by Fondazione Together To Go Onlus in Italy, this project aims to develop a new cooperative methodology for designing
                                                rehabilitation models and tools, making them accessible to a broader audience beyond researchers and treatment centers.
                                                Additionally, it will  produce two open-source software for eye pointing, an instruction manual for the research process developed through the project.
                                            </div>
                                            <div className="grantee-opencall-projects-card-link">
                                                watch their video presentation here
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {service === 2 && !showResults && (
                                <div className="services-tabs-body-data">
                                    <div className="services-tabs-tools-introduction">
                                        <h2>You can select more than one option:</h2>
                                        <button
                                            type="submit"
                                            id="results-btn"
                                            disabled={selectedItems.length === 0}
                                            onClick={(event) => { event.preventDefault(); setCurrentPage(1); showToolResults(); }}
                                        >
                                            GO TO RESULTS PAGE →
                                        </button>
                                    </div>
                                    <div className="services-tabs-columns-container">
                                        <div className="services-tabs-column">
                                            <h3>I work in</h3>
                                            <div className="services-tabs-column-works">
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("Research") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Research"); }}>
                                                    <h4>Research</h4>
                                                    <button type="button" className="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Higher education institutions/universities, research organizations">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("Government, Public Sector") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Government, Public Sector"); }}>
                                                    <h4>Government, Public Sector</h4>
                                                    <button type="button" className="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Government at any level/ministries, agencies, public bodies">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("NGO, Civil Society") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("NGO, Civil Society"); }}>
                                                    <h4>NGO, Civil Society</h4>
                                                    <button type="button" className="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Non-government organizations, not-for-profits, civil society organizations">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("Business") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Business"); }}>
                                                    <h4>Business</h4>
                                                    <button type="button" className="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Private sector organizations, industry, entrepreneurs">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("Other") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Other"); }}>
                                                    <h4>Other</h4>
                                                    <button type="button" className="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Can’t find yourself in any category? Click here">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="services-tabs-column">
                                            <h3>Areas I am interested in</h3>
                                            <div className="services-tabs-column-interests">
                                                <div className="services-tabs-column-interests-1">
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("AI Ethics") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("AI Ethics"); }}>
                                                        <h4>AI Ethics</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Corporal Social Responsability") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Corporal Social Responsability"); }}>
                                                        <h4>Corporal Social Responsability</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Ethics in Innovation") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Ethics in Innovation"); }}>
                                                        <h4>Ethics in Innovation</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Environmental Sustainability") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Environmental Sustainability"); }}>
                                                        <h4>Environmental Sustainability</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Economic Sustainability") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Economic Sustainability"); }}>
                                                        <h4>Economic Sustainability</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Governance") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Governance"); }}>
                                                        <h4>Governance</h4>
                                                    </div>
                                                </div>
                                                <div className="services-tabs-column-interests-2">
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Open Access & Open Science") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Open Access & Open Science"); }}>
                                                        <h4>Open Access & Open Science</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Ethics in Research & Innovation") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Ethics in Research & Innovation"); }}>
                                                        <h4>Ethics in Research & Innovation</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Open & Responsible Innovation") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Open & Responsible Innovation"); }}>
                                                        <h4>Open & Responsible Innovation</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Diversity, Equality, Inclusivity") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Diversity, Equality, Inclusivity"); }}>
                                                        <h4>Diversity, Equality, Inclusivity</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Public Engagement") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Public Engagement"); }}>
                                                        <h4>Public Engagement</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Social Sustainability") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Social Sustainability"); }}>
                                                        <h4>Social Sustainability</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="services-tabs-column">
                                            <h3>I search for</h3>
                                            <div className="services-tabs-column-works">
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("Tools") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Tools"); }}>
                                                    <h4>Tools</h4>
                                                    <button type="button" className="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Find tools for the implementation of your ORRI project">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("Success") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Success"); }}>
                                                    <h4>Success Stories /Experiences</h4>
                                                    <button type="button" className="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Be inspired by ORRI projects and their outcomes">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("Publications") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Publications"); }}>
                                                    <h4>Publications</h4>
                                                    <button type="button" className="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Access important publications in the ORRI domain">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("Recommendations") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Recommendations"); }}>
                                                    <h4>Recommendations</h4>
                                                    <button type="button" className="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Find policy recommendations">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {service === 2 && showResults && selectedResult === null && (
                                <div className="services-tabs-body-data">
                                    <div className="breadcrumb">
                                        Tools / <span>Result Page</span>
                                    </div>
                                    <div className="back" onClick={(event) => { event.preventDefault(); setShowResults(false); }}>
                                        <FaArrowLeft />Back to TOOLS
                                    </div>
                                    <div className="tools-title">
                                        Results
                                    </div>
                                    <div className="tools-selections">
                                        {
                                            selectedItems.map((item, index) => (
                                                <div key={index} className="tools-selections-element">
                                                    <span>{item}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="tools-results">
                                        {
                                            results.map((result, index) => (
                                                <div key={index} className={`tools-results-card ${result.image ? 'tools-results-card-image' : 'tools-results-card-no-image'}`}>
                                                    {result.image && (
                                                        <div className="tools-results-card-image">
                                                            <img src={`data:image/png;base64,${result.image}`} alt={`tool${index}`} />
                                                        </div>
                                                    )}
                                                    <div className="tools-results-card-title">
                                                        {result.title}
                                                    </div>
                                                    <div className="tools-results-card-text">
                                                        {result.summary}
                                                    </div>
                                                    <a href="/#" onClick={(event) => { event.preventDefault(); selectResult(result.id); }}></a>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="row mt-2">
                                        {
                                            totalPages > 1 &&
                                            <div className="col">
                                                <ReactPaginate
                                                    previousLabel={"<"}
                                                    nextLabel={">"}
                                                    pageCount={totalPages}
                                                    onPageChange={({ selected }) => { showToolResults(selected + 1) }}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"page-link previous"}
                                                    nextLinkClassName={"page-link next"}
                                                    disabledClassName={"pagination__link--disabled"}
                                                    activeClassName={"active"}
                                                    breakClassName={'page-item break2'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link'}
                                                    currentPage={currentPage - 1}>
                                                </ReactPaginate>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                            {service === 2 && showResults && selectedResult !== null && (
                                <div className="services-tabs-body-data">
                                    <div className="breadcrumb">
                                        Tools / Result Page / <span>{selectedResult.title}</span>
                                    </div>
                                    <div className="back" onClick={(event) => { event.preventDefault(); setSelectedResult(null); }}>
                                        <FaArrowLeft />Back to results page
                                    </div>
                                    <div className="tool-container">
                                        <div className="left">
                                            <div className="tool-title">
                                                {selectedResult.title}
                                            </div>
                                            {
                                                selectedResult.image && <div className="tool-image">
                                                    <img src={`data:image/png;base64,${selectedResult.image}`} alt={selectedResult.title} />
                                                </div>
                                            }
                                            <div className="tool-summary">
                                                {selectedResult.summary}
                                            </div>
                                            <div className="tool-results">
                                                <div className="title">
                                                    Publish information
                                                </div>
                                                <p><span>Author:</span> {selectedResult.authors}</p>
                                                <p><span>Year of publication:</span> {selectedResult.yearOfPublication}</p>
                                                <p><span>DOI:</span> {selectedResult.url}</p>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="tool-language">
                                                <div className="title">
                                                    Language
                                                </div>
                                                <p>
                                                    {
                                                        selectedResult.resourceTypes.map((keyword, index) => (
                                                            <span key={index}>{keyword}</span>
                                                        ))
                                                    }
                                                    {
                                                        selectedResult.resourcePotentialUsers.map((keyword, index) => (
                                                            <span key={index}>{keyword}</span>
                                                        ))
                                                    }
                                                </p>
                                            </div>
                                            <div className="tool-keywords">
                                                <div className="title">
                                                    Keywords
                                                </div>
                                                <p>
                                                    {
                                                        selectedResult.keywords.map((keyword, index) => (
                                                            <span key={index}>{keyword}</span>
                                                        ))
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {service === 3 && (
                                <div className="services-tabs-body-data">
                                    <div className="services-tabs-body-data">
                                        <div className="services-tabs-body-data-cards" id="services-tabs-body-data-cards-3">
                                            <div className="services-tabs-cards-info">
                                                <div className="services-tabs-card disabled">
                                                    <a href="/orri-map" id="open-call-link">Crash Courses</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {service === 4 && (
                                <div className="services-tabs-body-data">

                                </div>
                            )}
                            {service === 5 && (
                                <div className="services-tabs-body-data">

                                    <Form noValidate onSubmit={handleSubmit(onSubmit)} id="new-tool-form">

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="resource_name">
                                                <strong>Name of the resource</strong>
                                            </label>
                                            <input
                                                type="text"
                                                {...register("resource_name")}
                                                onInput={handleWordTitleLimit}
                                                className={`form-control mt-1 ${errors.resource_name ? 'is-invalid' : ''}`}
                                                id="resource_name"
                                            />
                                            {wordLimitReached50 && (
                                                <div className="alert alert-warning d-flex align-items-center mt-2" role="alert">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                                    </svg>
                                                    <div>
                                                        Has alcanzado el límite de 50 palabras.
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="resource_authors">
                                                <strong>Name of the authors</strong>
                                            </label>
                                            <input
                                                type="text"
                                                {...register("resource_authors")}
                                                onInput={handleWordTitleLimitAuthors}
                                                className={`form-control mt-1 ${errors.resource_authors ? 'is-invalid' : ''}`}
                                                id="resource_authors"
                                            />
                                            {wordLimitReached50Authors && (
                                                <div className="alert alert-warning d-flex align-items-center mt-2" role="alert">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                                    </svg>
                                                    <div>
                                                        Has alcanzado el límite de 50 palabras.
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="summary">
                                                <strong>Summary</strong>
                                            </label>
                                            <textarea
                                                rows={14}
                                                {...register("summary")}
                                                onInput={handleWordSummaryLimit}
                                                className={`form-control mt-1 ${errors.summary ? 'is-invalid' : ''}`}
                                                id="summary"
                                                style={{ minHeight: '100px', maxHeight: '300px', resize: 'vertical', overflowY: 'auto' }}
                                            ></textarea>
                                            {wordLimitReached300 && (
                                                <div className="alert alert-warning d-flex align-items-center mt-2" role="alert">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                                    </svg>
                                                    <div>
                                                        Has alcanzado el límite de 300 palabras.
                                                    </div>
                                                </div>
                                            )}
                                            {errors.summary && (
                                                <p className="error-message">{errors.summary.message}</p>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="publication_year"><strong>Year of publication</strong></label>
                                            <select
                                                {...register("publication_year", { required: 'Year of publication is required' })}
                                                className={`form-control mt-1 ${errors.publication_year ? 'is-invalid' : ''}`}
                                                id="publication_year"
                                            >
                                                <option value="">Select a year of publication</option>
                                                {Array.from(new Array(new Date().getFullYear() - 1699), (val, index) => 1700 + index).reverse().map(year => (
                                                    <option key={year} value={year}>{year}</option>
                                                ))}
                                            </select>
                                            {errors.resource_type && (
                                                <p className="error-message">{errors.publication_year.message}</p>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="image"><strong>Image</strong></label>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                className={`form-control mt-1 ${errors.image ? 'is-invalid' : ''}`}
                                                id="image"
                                                {...register("image")}
                                                onChange={handleImageChange}
                                            />
                                            {selectedImage && (
                                                <div className="mt-2">
                                                    <img
                                                        src={URL.createObjectURL(selectedImage)}
                                                        alt="Selected"
                                                        style={{ maxWidth: '100px' }}
                                                    />
                                                    <button
                                                        type="button"
                                                        id="remove-image-btn"
                                                        className="btn btn-danger mt-2"
                                                        onClick={handleRemoveImage}
                                                    >
                                                        Remove Image
                                                    </button>
                                                </div>
                                            )}
                                            {errors.image && (
                                                <p className="error-message">{errors.image.message}</p>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="link"><strong>Link</strong> (URL)</label>
                                            <input
                                                type="url"
                                                {...register("link", { required: 'Link is required' })}
                                                className={`form-control mt-1 ${errors.link ? 'is-invalid' : ''}`}
                                                id="link"
                                            />
                                            {errors.link && (
                                                <p className="error-message">{errors.link.message}</p>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="resource_type"><strong>Type of resource</strong> (select one)</label>
                                            <select
                                                {...register("resource_type", { required: 'Resource type is required' })}
                                                className={`form-control mt-1 ${errors.resource_type ? 'is-invalid' : ''}`}
                                                id="resource_type"
                                            >
                                                <option value="">Select a resource type</option>
                                                {resourceTypes.map((type) => (
                                                    <option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.resource_type && (
                                                <p className="error-message">{errors.resource_type.message}</p>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="keywords"><strong>Keywords</strong> (you can select several fields)</label>
                                            {keywords.map((item) => (
                                                <div key={item.id} className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={item.id}
                                                        id={item.id}
                                                        {...register("keywords")}
                                                    />
                                                    <label className="form-check-label" htmlFor={item.id}>
                                                        {item.name}
                                                    </label>
                                                </div>
                                            ))}
                                            {errors.keywords && (
                                                <p className="error-message">{errors.keywords.message}</p>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <div className="form-group mt-3">
                                            <label className="form-label" htmlFor="potential_users"><strong>Potential user of the resource</strong> (select several, but try to avoid as much as possible the use of the “Other”)</label>
                                            {potentialUsers.map((item) => (
                                                <div key={item.id} className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={item.id}
                                                        id={item.id}
                                                        {...register("potential_users")}
                                                    />
                                                    <label className="form-check-label" htmlFor={item.id}>
                                                        {item.name}
                                                    </label>
                                                </div>
                                            ))}
                                            {errors.potential_users && (
                                                <p className="error-message">{errors.potential_users.message}</p>
                                            )}
                                        </div>

                                        {/* -------------------------------------------------------------------------------- */}

                                        <Button
                                            type="submit"
                                            variant="primary"
                                            className="btn btn-primary mt-5 register"
                                            form="new-tool-form"
                                            id="form-btn"
                                        >
                                            <span><strong>Submit</strong></span>
                                        </Button>

                                    </Form>

                                </div>
                            )}
                        </div>
                        {service === 0 && (
                            <div className="services-tabs-body-links-container">
                                <div className="services-tabs-body-links">
                                    <div className="services-tabs-link-card">
                                        <h1>Interested in match-making?</h1>
                                        <a href="/orri-map" id="orri-map-link">Find ORRI practitioners across Europe →</a>
                                    </div>
                                    <div className="services-tabs-link-card">
                                        <h1>Interested in building capacity?</h1>
                                        <a href="/#" id="building-link">Find ORRI training resources →</a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Newsletter />
            <ContactUs />
            <SocialAndNewsletter />
            <Footer />
        </div>
    );
}